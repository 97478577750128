/** @format */

.bacode-image {
  cursor: pointer;
  max-width: 80%;
  &:hover {
    transform: scale(1.1);
  }
  transition: all 0.5s linear;
}

.incon {
  cursor: pointer;
  &:hover {
    color: crimson;
  }
}

th {
  text-align: center;
}

.rdt.rdtOpen .rdtPicker {
  width: 250px;
}

.order-table-container {
  width: 85%;
  margin: 50px auto;
  // border: 1px solid #e9e9ef;
  // padding: 2rem;
  // border-radius: 0.375rem;
  // background-color: white;
}

.search-select {
  width: 150px;
}

.search-input {
  width: 250px;
}

.search-index {
  width: 100px;
}

$btnColorDefault: rgb(210, 210, 239);

.default-color {
  background-color: $btnColorDefault;
  border-color: $btnColorDefault;
  &:hover {
    background-color: $btnColorDefault;
    border-color: $btnColorDefault;
  }
}

.over {
  background-color: rgb(247, 247, 247) !important;
}

.over-text {
  background-color: rgb(231, 230, 230) !important;
  color: gray !important;
}

.progress-pending {
  background-color: rgb(229, 229, 97);
}

.progress-ing {
  background-color: rgb(238, 176, 61);
}

.progress-success {
  background-color: rgb(111, 200, 111);
}

.progress-danger {
  background-color: rgb(237, 98, 126);
}

.page-index {
  display: flex;
  align-items: center;
}

.fa-exclamation-triangle {
  color: rgb(225, 78, 107);
}

.fa-trash-alt {
  cursor: pointer;
  &:hover {
    color: crimson;
  }
}

hr {
  margin: 1.5rem auto;
}

.has-danger:after {
  content: none !important;
}

.add-date {
  input {
    width: 100% !important;
    background-color: white !important;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1200px) {
  /* 테블릿 CSS */
  .order-table-container {
    width: 95%;
    margin: auto;
    // margin-top: 70px;
  }
}

@media only screen and (max-width: 960px) {
  .page-index {
    display: none;
  }
  .order-table {
    display: none;
  }

  .search-input {
    width: auto;
  }
}
