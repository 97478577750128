/** @format */

.register {
  height: 95vh;
}

.invalid {
  border-color: #fb6340 !important;
}

.feedbackform {
  position: absolute;
}
