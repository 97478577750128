.time-container {
  width: 30%;
  display: grid;
  grid-template-areas:
    "design product_order product_purchase assemble"
    "confirm drive complete complete";
  row-gap: 10px;
  column-gap: 20px;
  margin-bottom: 10px;

  .design {
    grid-area: design;
  }
  .product_order {
    grid-area: product_order;
  }
  .product_purchase {
    grid-area: product_purchase;
  }
  .assemble {
    grid-area: assemble;
  }
  .confirm {
    grid-area: confirm;
  }
  .drive {
    grid-area: drive;
  }
  .complete {
    grid-area: complete;
  }

  .design,
  .product_order,
  .product_purchase,
  .assemble,
  .confirm,
  .drive {
    width: 200px;
  }

  .fa-pen,
  .fa-save,
  .fa-window-close {
    cursor: pointer;
    &:hover {
      color: crimson;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
