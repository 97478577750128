.confirm-btn {
  width: 10%;
}

.confirm-status {
  display: block;
}

.confirm-status-card {
  width: 100%;
  margin-bottom: 15px;
}

.before {
  background-color: rgb(240, 232, 247);
  color: rgb(219, 222, 222);
}

@media only screen and (max-width: 750px) {
  .confirm-btn {
    min-width: 2.8rem;
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .confirm-btn {
    width: 10%;
  }

  .confirm-status {
    display: grid;
    grid-template-areas:
      "1 2 3"
      "4 5 6";
    // display: flex;
    // grid-template-areas: "1 2 3 4";
    // column-gap: 30px;
  }

  .confirm-status-card {
    width: 90%;
    margin-right: 30px;
  }
}
