.drive-btn {
  width: 30%;
}

.drive-status {
  display: block;
}

.drive-status-card {
  width: 100%;
  margin-bottom: 15px;
}

@media only screen and (max-width: 750px) {
  .drive-btn {
    width: 30%;
  }
}

@media only screen and (min-width: 1000px) {
  .drive-btn {
    width: 20%;
  }

  .drive-status {
    display: flex;
    // grid-template-areas: "1 2 3 4";
    // column-gap: 30px;
  }

  .drive-status-card {
    width: 45%;
    margin-right: 30px;
  }
}

.before {
  background-color: rgb(240, 232, 247);
  color: rgb(219, 222, 222);
}
