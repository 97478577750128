.default-container {
  display: flex;
  justify-content: center;
}

.product-table {
  width: 35rem;
  margin: 2rem auto 0px auto;
}

@media only screen and (max-width: 1550px) {
  .default-container {
    display: block;
    margin: auto;
  }

  .default-info {
    margin: auto;
  }
  .hr {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .product-table {
    width: 800px;
    margin: 2rem auto 0px auto;
  }
}

@media only screen and (max-width: 750px) {
  .product-table {
    width: 100%;
    margin: 2rem auto 0px auto;
  }
}

@media only screen and (min-width: 1550px) {
  .product-table {
    width: 500px;
    margin: 2rem 0 0px 3rem;
  }
  .default-container {
    display: flex;
    justify-content: center;
  }

  .hr {
    display: none;
  }
}
