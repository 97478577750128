.assemble-btn {
  width: 30%;
}

.assemble-status {
  display: block;
}

.assemble-status-card {
  width: 100%;
  margin-bottom: 15px;
}

.assemble-quantity {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-areas: "1 2 3 4";
  column-gap: 30px;
  row-gap: 10px;
}

.assemble-quantity-card {
  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.step-conteiner {
  display: grid;
  grid-template-areas: "1 2";
  grid-auto-columns: 0.5fr 4fr;
  column-gap: 5px;
}

.fa-edit {
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: crimson;
  }
}

.picker-container {
  .picker {
    display: flex;
    // display: grid;

    // grid-template-areas: "1 2";
    // grid-auto-columns: 3fr 1.5fr;
    // column-gap: 5px;
  }

  .fa-save {
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: rgb(147, 203, 147);
    }
  }
  .fa-window-close {
    margin-left: 20px;
    font-size: 1rem;
    cursor: pointer;
    &:hover {
      color: rgb(226, 177, 154);
    }
  }
}

.ant-picker {
  .ant-picker-input {
    input {
      color: #8898aa !important;
    }
  }
  display: block !important;
  width: 100% !important;
  height: calc(1.5em + 1.25rem + 2px) !important;
  padding: 0.625rem 0.75rem !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #cad1d7 !important;
  border-radius: 0.375rem !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
  color: inherit !important;
  font-size: inherit !important;
  font-family: inherit !important;
  line-height: inherit !important;
  touch-action: manipulation !important;
}

@media only screen and (max-width: 750px) {
  .assemble-btn {
    width: 30%;
  }
  .assemble-quantity {
    display: grid;
    grid-template-areas: "1 2";
    column-gap: 30px;
    row-gap: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .assemble-btn {
    width: 20%;
  }

  .assemble-status {
    display: flex;
    // grid-template-areas: "1 2 3 4";
    // column-gap: 30px;
  }

  .assemble-status-card {
    width: 35%;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 1500px) {
  .assemble-quantity {
    display: grid;
    grid-template-areas: "1 2 3 4 5 6";
    column-gap: 30px;
    row-gap: 10px;
  }
}

.before {
  background-color: rgb(240, 232, 247);
  color: rgb(219, 222, 222);
}
