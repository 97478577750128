/** @format */

/** @format */
@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");

.title {
  // text-align: center;
  // letter-spacing: 0.5px !important;
  // font-size: 2.3rem !important;
  // font-family: "Nunito", sans-serif;
  // line-height: 1.5 !important;
  // font-weight: 600 !important;
  // margin-bottom: 80px;
  // span {
  //   font-size: 1.5rem !important;
  //   margin-left: 1rem;
  // }

  font-family: "Nunito", sans-serif;
  // line-height: 1.5;
  font-weight: 600;
  font-size: 24px !important;
  color: #525f7f;
}

.status_card_container {
  margin-top: 70px;
}

.order-card {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  // background-image: url("../img/theme/test.jpg");
  background-size: 150px;
  // filter: grayscale(100%);
}

.fa-angle-double-right {
  display: inline-block;
  margin: 0px 15px;
}

.block-card {
  position: absolute;
  background-color: black !important;
  opacity: 0.4;
  z-index: 100;
}

.status-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 1.5rem;
}

.nav .active {
  background-color: rgb(166, 165, 165);
  opacity: 0.5;
  color: black;
  font-weight: bold;
  border-radius: 10px;
  cursor: auto !important;
  &:hover {
    color: black;
  }
}

.deactive {
  background-color: white;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #e9e9ef;
}

// .tab-card-body {
//   width: 800px;
// }

.form-inline label {
  display: inline-block;
}

.default {
  width: 100% !important;
  input {
    width: 100% !important;
    background-color: white !important;
    cursor: pointer;
  }
  margin-top: 0.5rem;
}

.model-input {
  width: 100% !important;
  input {
    width: 100% !important;
    background-color: white !important;
    cursor: pointer;
  }

  margin-top: 0.5rem;
}

.card-container {
  display: grid;
  grid-template-areas: "1 2 3 4 5 6 7 8";
}

// .step-container {
//   max-width: 85vw;
// }

.beforeStep {
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-10px);
  }
}

.clientTarget {
  border-radius: 50%;
  width: calc(9rem + 0.3rem);
  height: calc(9rem + 0.3rem);
  border: 0.3rem solid rgb(184, 184, 244) !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
}

.file-item {
  border-radius: 10px;
  background-color: rgb(230, 229, 229);
  width: 48.5%;
  &:hover {
    .delete {
      display: inline-block;
    }
  }
  .delete {
    display: none;
    background-color: white;
    // padding: 3px;
    width: 23px;
    height: 23px;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    // color: crimson;
    &:hover {
      color: crimson;
    }
  }
}

.drag-and-drop-container {
  position: relative;
}
.drag-and-drop {
  position: absolute;
  border-radius: 10px;
  min-height: 350px;
  width: calc(100% - 3rem);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px black dashed;
}

.container-zone {
  display: none;
}

.img-file {
  background-color: rgb(230, 229, 229);
  position: relative;
  border: white 0.5rem solid;

  &:hover {
    .delete {
      display: inline-block;
    }
  }
  img {
    display: inline-block;
    margin: auto;
    display: flex;
  }

  div {
    display: flex;
    justify-content: center;
    border-top: 4px solid white;
  }

  .delete {
    display: none;
    cursor: pointer;
    top: 5px;
    right: 5px;
    position: absolute;
    background-color: white;
    // padding: 3px;
    width: 23px;
    height: 23px;
    font-size: 15px;
    text-align: center;
    border-radius: 50%;
    &:hover {
      color: crimson;
    }
  }
}

// .test {
//   display: grid;
//   justify-content: center;
//   align-content: center;

//   gap: 4px;
//   grid-auto-flow: column;
// }

[class*="col_"] {
  float: left;
}
.col_s_1 {
  width: 8.33%;
}
.col_s_2 {
  width: 16.66%;
}
.col_s_3 {
  width: 25%;
}
.col_s_4 {
  width: 33.33%;
}
.col_s_5 {
  width: 41.66%;
}
.col_s_6 {
  width: 50%;
}
.col_s_7 {
  width: 58.33%;
}
.col_s_8 {
  width: 66.66%;
}
.col_s_9 {
  width: 75%;
}
.col_s_10 {
  width: 83.33%;
}
.col_s_11 {
  width: 91.66%;
}
.col_s_12 {
  width: 100%;
}

$pdf_width: 595px;

.div_container {
  width: 100%;
  display: flex;
  // width: 700px;
  // min-width: $pdf_width;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: grey;
  overflow: auto;
}

// .div_container > button {
//   position: fixed;
//   bottom: 30px;
//   right: 30px;
//   border: none;
//   border-radius: 8px;
//   padding: 20px;
//   box-shadow: 0 2px 2px #e4e4e4;
//   background-color: white;
//   cursor: pointer;
//   transition: 1s;
// }

// .div_container > button:hover {
//   background-color: black;
//   color: white;
// }

[class*="div_paper"] {
  // height: 842px;
  // width: 595px;
  height: $pdf_width * 1.55;
  width: $pdf_width;
  margin: 30px;
  padding: 30px;
  background-color: white;
  text-align: center;
  hr {
    margin: 1rem 0px;
  }
  .table {
    // width: 100% !important;
  }
}

// .div_paper > div {
//   margin: 30px;
// }

.logo-container {
  width: 50px;
}

.logo {
  max-width: 100%;
}

.info {
  text-align: right;
  font-size: 10px;
}

.info {
  p:nth-child(1) {
    font-weight: 600;
  }
  p {
    margin: 0px !important;
    font-size: 10px;
    color: black !important;
  }
}

.memo {
  border-bottom: 1px solid rgb(169, 167, 167);
  border-top: 1px solid rgb(169, 167, 167);
  p {
    margin: 0px !important;
    font-size: 10px;
    text-align: left;
    color: black !important;
  }

  div {
    padding: 0px 10px;
  }
}

.complete {
  font-family: none;
}

[class*="order-info"] {
  margin: 0px 0px !important;
  .table {
    width: 100% !important;
  }
  .pdf-table-header,
  .pdf-table-body {
    font-size: 8px;
    border-style: none;
    color: black !important;
    padding: 3px !important;
  }
  td,
  th {
    font-weight: 300 !important;
  }
}

.pdf-confirm {
  padding: 20px;
  border: 1px solid rgb(169, 167, 167);
  margin: 10px;
  width: 210px !important;
  display: inline-block;
}

.none {
  border: none;
}

.pdf-confirm-even {
  margin-left: 0px;
}

.pdf-confirm-img {
  max-width: 100%;
}

/*input 은 숨겨주기*/
input.check-box {
  display: none;
}
/*input 바로 다음의 label*/
input.check-box + label {
  cursor: pointer;
}

/*input 바로 다음의 label:before 에 체크하기 전 CSS 설정*/
input.check-box + label:before {
  content: "";
  display: inline-block;
  width: 17px;
  height: 17px;
  line-height: 17px;
  border: 1px solid #cbcbcb;
  vertical-align: middle; /*체크 전과 체크 후 높이 차이 때문에 설정*/
}

/*checked된 input 바로 다음의 label:before 에 체크 후 CSS 설정*/
input.check-box:checked + label:before {
  content: "\f00c"; /*폰트어썸 유니코드*/
  font-family: "Font Awesome 5 free"; /*폰트어썸 아이콘 사용*/
  font-weight: 900; /*폰트어썸 설정*/
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
  font-size: 13px;
  text-align: center;
}

.detail-btn {
  cursor: pointer;
}

.confirm-step-btn {
  span {
    cursor: pointer;
    &:hover {
      color: rgb(208, 145, 145);
    }
  }
}

@media only screen and (max-width: 1550px) {
  .card-container {
    display: grid;
    grid-template-areas: "1 2 3 4" "5 6 7 8";
  }
}

@media only screen and (max-width: 750px) {
  .card-container {
    display: grid;
    grid-template-areas:
      "1 2"
      "3 4"
      "5 6"
      "7 8";
  }
  .file-item {
    width: 100%;
    .delete {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 750px) {
  .div_container {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  /* 테블릿 CSS */
  .tab-card-body {
    width: 800px;
  }

  .bottom-container {
    width: 90vw;
  }
}
