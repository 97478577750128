#auto-complete {
  position: relative;
  div {
    // position: fixed;
    display: block !important;
    div {
      z-index: 10001;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
  input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }

  .auto-item {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    cursor: pointer;
  }
}

th {
  position: relative;
  .purchase-th {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(1.5em + 1.25rem + 2px);
  }
  .input-total {
    display: inline-block;
    width: 80px;
    text-align: right;
  }
  .fa-check-circle {
    color: #2dce89;
    font-size: 1.1rem;
  }
  .fa-times-circle {
    color: crimson;
    cursor: pointer;
    font-size: 1.1rem;
  }
}

.fa-plus-square {
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    color: crimson;
  }
}

@media only screen and (max-width: 750px) {
  .mobile {
    display: none;
  }
  th {
    .input-total {
      display: inline-block;
      width: 65px;
      text-align: right;
    }
  }
}
